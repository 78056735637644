export const apiFetch = function<R> (path: string, opts?: any) {
  const { api: { baseUrl } } = useAppConfig();

  if (process.client) {
    const telegramData = useTelegramWebApp()?.initData;

    if (telegramData) {
      opts = opts || {};

      opts.headers = {
        'X-Telegram-Web-App-Data': telegramData,
        ...opts.headers,
      }
    }
  }

  return $fetch<R>(path, { baseURL: baseUrl, ...opts });
};