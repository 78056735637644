import type CheckpointData from "~/api/response/data/CheckpointData";

export const enum CheckpointStatus {
    Open = 'open',
    Closed = 'closed',
}

export default class Checkpoint {
    code: string;
    name: string;
    countryCode: string;
    address: string;
    phone: string;
    status: CheckpointStatus;

    constructor(data: CheckpointData) {
        this.code = data.code;
        this.name = data.name;
        this.countryCode = data.country_code;
        this.address = data.address;
        this.phone = data.phone;
        this.status = data.status as CheckpointStatus;
    }

    get isOpen() {
        return CheckpointStatus.Open === this.status;
    }

    get isClosed() {
        return CheckpointStatus.Closed === this.status;
    }
}